var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticStyle: {
      "position": "sticky",
      "top": "72px",
      "margin-bottom": "40px",
      "z-index": "99",
      "padding": "0 20px"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.companies,
      "loading": _vm.searchLoading,
      "search-input": _vm.search,
      "clearable": "",
      "hide-details": "",
      "hide-selected": "",
      "item-text": "name",
      "item-value": "id",
      "label": "Search for company...",
      "outlined": "",
      "required": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "click:clear": function ($event) {
        _vm.companyId = null;
      }
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" No data available")])], 1)];
      },
      proxy: true
    }, {
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', {
          domProps: {
            "textContent": _vm._s(item.name)
          }
        })];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var _item$fullName;
        var item = _ref2.item;
        return [_c('v-list-item-avatar', {
          staticClass: "text-h5 font-weight-light white--text",
          attrs: {
            "color": "primary"
          }
        }, [true ? _c('v-icon', {
          attrs: {
            "color": "#fff"
          }
        }, [_vm._v("mdi-domain")]) : _c('v-icon', {
          attrs: {
            "color": "#fff"
          }
        }, [_vm._v("mdi-account")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "textContent": _vm._s((_item$fullName = item.fullName) !== null && _item$fullName !== void 0 ? _item$fullName : item.name)
          }
        }), _c('v-list-item-subtitle', {
          domProps: {
            "textContent": _vm._s(item.email)
          }
        })], 1)];
      }
    }]),
    model: {
      value: _vm.companyId,
      callback: function ($$v) {
        _vm.companyId = $$v;
      },
      expression: "companyId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "outlined": "",
      "clearable": "",
      "autofocus": "",
      "prepend-inner-icon": "mdi-magnify",
      "label": "Search by Invoice Number...",
      "hint": "Search by Mango order ID or external booking ID",
      "hide-details": "",
      "color": "orange"
    },
    model: {
      value: _vm.fortnoxNumber,
      callback: function ($$v) {
        _vm.fortnoxNumber = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "fortnoxNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-4 align-stretch font-weight-bold",
    attrs: {
      "outlined": "",
      "items": _vm.tenants,
      "item-text": "name",
      "hide-details": "",
      "item-value": "id",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Select tenants",
      "multiple": "",
      "persistent-hint": false
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref3) {
        var item = _ref3.item,
          index = _ref3.index;
        return [index < 3 ? _c('v-chip', [_c('span', [_vm._v(_vm._s(item.name))])]) : _vm._e(), index === 3 ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_vm._v(" (+" + _vm._s(_vm.selectedTenants.length - 3) + ") ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selectedTenants,
      callback: function ($$v) {
        _vm.selectedTenants = $$v;
      },
      expression: "selectedTenants"
    }
  })], 1)], 1)], 1), _vm._l(_vm.statusList, function (item) {
    return _c('InvoiceTable', {
      key: item.id,
      attrs: {
        "status": item.id,
        "selectedTenants": _vm.selectedTenantsForTable,
        "tenants": _vm.tenants,
        "companyId": _vm.companyId,
        "fortnoxNumber": _vm.fortnoxNumber,
        "headers": _vm.headers,
        "updatedStatus": _vm.updatedStatus
      },
      on: {
        "onUpdate": function ($event) {
          _vm.updatedStatus = $event;
        }
      }
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }