<template>
  <div>
    <v-card
      style="
        position: sticky;
        top: 72px;
        margin-bottom: 40px;
        z-index: 99;
        padding: 0 20px;
      "
    >
      <v-row>
        <v-col cols="12" lg="4" md="12" sm="12" xs="12">
          <v-autocomplete
            v-model="companyId"
            :items="companies"
            :loading="searchLoading"
            :search-input.sync="search"
            @click:clear="companyId = null"
            clearable
            hide-details
            hide-selected
            item-text="name"
            item-value="id"
            label="Search for company..."
            outlined
            required
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title> No data available</v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-avatar
                color="primary"
                class="text-h5 font-weight-light white--text"
              >
                <v-icon v-if="true" color="#fff">mdi-domain</v-icon>
                <v-icon v-else color="#fff">mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.fullName ?? item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.email"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" lg="4" md="12" sm="12" xs="12">
          <v-text-field
          v-model.trim="fortnoxNumber"
          outlined
          clearable
          class="font-weight-bold"
          autofocus
          prepend-inner-icon="mdi-magnify"
          label="Search by Invoice Number..."
          hint="Search by Mango order ID or external booking ID"
          hide-details
          color="orange"
        ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="12" sm="12" xs="12">
          <v-select
            outlined
            class="mb-4 align-stretch font-weight-bold"
            v-model="selectedTenants"
            :items="tenants"
            item-text="name"
            hide-details
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="Select tenants"
            multiple
            :persistent-hint="false"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 3" class="grey--text text-caption">
                (+{{ selectedTenants.length - 3 }})
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card>

    <InvoiceTable
      v-for="item in statusList"
      :key="item.id"
      :status="item.id"
      :selectedTenants="selectedTenantsForTable"
      :tenants="tenants"
      :companyId="companyId"
      :fortnoxNumber="fortnoxNumber"
      :headers="headers"
      :updatedStatus="updatedStatus"
      @onUpdate="updatedStatus = $event"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Trainplanet from "@/util/trainplanet.api";
import { debounce } from "@/util/helpers";
import moment from "moment";
import InvoiceTable from "./components/InvoiceTable.vue";

export default {
  components: {  InvoiceTable },
  data: () => ({
    updatedStatus: null,
    searchLoading: false,
    searchValue: null,
    companies: [],
    search: "",
    isSearch: false,
    editItem: null,
    companyId: "",
    fortnoxNumber:"",
    saveLoader: false,
    dialog: false,
    loading: false,
    comment: "",
    selectedTenants: [],
    selectedTenantsForTable: [],
    invoices: [],
    searchResults: [],
    statusList: [
      {
        label: "Pending",
        id: 0,
      },
      {
        label: "Invoiced",
        id: 1,
      },
      {
        label: "Paid",
        id: 2,
      },
      {
        label: "Accounted",
        id: 3,
      },
    ],
    headers: [
      {
        text: "Order ID",
        value: "orderId",
        width:200,
      },
      {
        text: "Linkonline IDs",
        value: "bookings",
        width: 120,
      },
      {
        text: "Invoice Number",
        value: "fortnoxNumber",
        width: 130,
      },
      {
        text: "Company",
        value: "company.name",
        width: 180,
      },
      {
        text: "Customer",
        value: "customer.firstName",
        width: 220,
      },
      {
        text: `Order Debited (GMT +${moment().utcOffset() / 60})`,
        value: "created",
        width: 180,
      },
      // {
      //   text: "Debited By",
      //   value: "debitedBy",
      //   width: 160,
      // },
      {
        text: `Must Be Paid Before`,
        value: "mustBePaidDate",
        width: 155,
        sortable: false
      },
      {
        text: "Amount",
        value: "order.calculatedPrice.total.plainText",
        align: "right",
        width: 120,
        sortable: false
      },
      {
        text: "Actions",
        value: "actions",
        align: "right",
        width: 140,
        sortable: false
      },
    ],
  }),
  mounted() {
    this.$store.dispatch("loading");
    this.selectedTenants = this.tenants.map((item) => item.id);
  },
  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
    }),
  },
  watch: {
    search: {
      deep: true,
      handler: debounce(function (val) {
        if (val && val.length > 1) {
          this.searchLoading = true;

          Trainplanet.getCompanyList({ q: val })
            .then((res) => {
              this.companies = res.company.map((r) => ({...r, key: val}));
            })
            .catch((err) => {
              this.$store.dispatch("error", err);
            })
            .finally(() => (this.searchLoading = false));
        }
      }, 200),
    },
    searchValue: {
      deep: true,
      handler: debounce(function (val) {
        this.getCompanyList();
      }, 300),
    },
    selectedTenants: {
      deep: true,
      handler: debounce(function (val) {
        this.selectedTenantsForTable = [...val];
      }, 500),
    },
    tenants: {
      deep: true,
      handler: function (value) {
        this.selectedTenants = [...this.tenants.map((item) => item.id)];
      },
    },
    // companyId: {
    //   deep: true,
    //   handler: function (value) {
    //     if (value) {
    //       this.getInvoiceList();
    //     }
    //   }
    // },

  },
  methods: {
    convertBookingsToBookingNumbers(bookings) {
      return bookings?.map((booking) => booking.externalOrderId);
    },
    async getCompanyList() {
      try {
        this.loading = true;
        this.searchLoading = true;
        const params = {};
        if (this.searchValue) params.q = this.searchValue;
        this.companies = await Trainplanet.getCompanyList({
          tenantIds: this.selectedTenants,
          ...params,
        });
      } finally {
        this.loading = false;
        this.searchLoading = false;
      }
    },
  },
  filters: {
    getOnlyDate(datetime) {
      return moment.utc(datetime).local().format("YYYY-MM-DD");
    },
    getOnlyTime(datetime) {
      return moment.utc(datetime).local().format("HH:mm");
    },
  },
};
</script>
<style lang="scss" scoped>
div[role="combobox"] {
  background: red !important;
}

</style>
